















































































.wapNewBanner {
  &.landscape {
    display: none;
  }
}
.banner-container {
  width: 100%;
  height: 326px;
  overflow: hidden;
  position: relative;

  .banner-item {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background: #fff;
    }
  }
}

.wap-banner {
  height: 143px;
  margin-bottom: 0;
  &.landscape {
    height: 198px;
  }

  /deep/ .swiper-pagination {
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 10;
    transform: translateX(-50%);
    width: 100%;
    display: flex;
    justify-content: center;
    .swiper-pagination-bullet {
      width: auto;
      height: 3px;
      background: #c4c4c4;
      opacity: 1;
      border-radius: 0;
      margin-right: 2px;
      flex: 1;
    }

    .swiper-pagination-bullet-active {
      background: #6bc7ff;
    }
  }
  &.landscape {
    .banner-item {
      img {
        height: 195px;
      }
    }
  }

  .banner-item {
    img {
      height: 140px;
    }
  }
}

.pc-banner {
  /deep/ .swiper-pagination {
    position: absolute;
    bottom: 16px;
    right: 24px;
    z-index: 10;

    .swiper-pagination-bullet {
      width: 14px;
      height: 4px;
      background: #cccccc;
      border-radius: 3px;
      opacity: 1;
      margin: 0 2px;
    }

    .swiper-pagination-bullet-active {
      width: 24px;
      background: #ffb534;
    }
  }

  .banner-item {
    img {
      height: 326px;
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.game-recommend {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
}
.game-recommend.landscape {
  flex-direction: row;
}
.game-recommend.landscape .game-video {
  width: 670px;
  height: 350px;
  margin-right: 20px;
}
.game-recommend.landscape .game-video .video {
  width: 670px;
  height: 350px;
}
.game-recommend .game-video {
  width: 340px;
  height: 192px;
  position: relative;
  overflow: hidden;
  margin-bottom: 22px;
}
.game-recommend .game-video .video {
  width: 340px;
  height: 192px;
}
.game-recommend .game-video .video video {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}
.game-recommend .game-video .play-icon {
  display: inline-block;
  width: 41px;
  height: 41px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.game-recommend .game-video .title {
  width: 100%;
  max-height: 38px;
  font-size: 14px;
  font-weight: 500;
  color: #171717;
  line-height: 19px;
  margin: 10px 0 23px 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.game-recommend .recommend-list {
  width: 340px;
  height: auto;
  box-sizing: content-box;
  padding: 0;
  display: grid;
  grid-gap: 13px;
  grid-template-columns: repeat(2, 1fr);
  background-size: cover;
}
.game-recommend .recommend-list .recommend-item {
  position: relative;
  width: 100%;
  height: 160px;
  border-radius: 6px;
  border: 1px solid #ececec;
  overflow: hidden;
}
.game-recommend .recommend-list .recommend-item.active {
  background-color: #e5f7fc;
}
.game-recommend .recommend-list .recommend-item.active .title {
  color: #00afe8;
}
.game-recommend .recommend-list .recommend-item .cover {
  width: 100%;
  height: 108px;
  cursor: pointer;
  overflow: hidden;
}
.game-recommend .recommend-list .recommend-item .cover img {
  width: 100%;
  height: 100%;
  transition: all 0.2s;
}
.game-recommend .recommend-list .recommend-item .cover img:hover {
  transform: scale(1.05);
}
.game-recommend .recommend-list .recommend-item .title {
  height: 38px;
  font-size: 14px;
  font-weight: 500;
  color: #171717;
  line-height: 19px;
  margin: 8px 0;
  padding: 0 8px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.game-recommend .recommend-list .recommend-item .info {
  position: absolute;
  top: 87px;
  width: 100%;
  height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  line-height: 18px;
  display: flex;
  justify-content: space-between;
  padding: 0 8px;
}
.game-recommend .recommend-list .recommend-item .info span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.game-recommend .recommend-list .recommend-item .info span:first-child {
  flex: 1;
}
.game-recommend .recommend-list .recommend-item .info span .wiki-icon {
  display: inline-block;
  vertical-align: top;
  width: 17px;
  height: 17px;
  margin-right: 2px;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}

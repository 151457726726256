 /deep/ .video-player {
  background: transparent;
  box-shadow: none;
}
 /deep/ .video-player .el-dialog__header .el-dialog__headerbtn {
  top: 10px;
  right: 5px;
}
 /deep/ .video-player .el-dialog__header .el-dialog__headerbtn .el-icon-close {
  font-size: 24px;
  color: #fff;
}
.custom-title {
  display: flex;
  width: 375px;
  margin-bottom: 13px;
}
.custom-title .title-item {
  display: block;
  width: 100%;
  height: 39px;
  font-size: 13px;
  font-weight: 400;
  color: #c1c1c1;
  line-height: 39px;
  cursor: pointer;
  background: #eeeeee;
  text-align: center;
}
.custom-title .title-item.active {
  color: #333333;
  background: #fff;
  border-top: 1px solid #747474;
}
.game-list-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.game-list-container.landscape {
  flex-direction: row;
}
.game-list-container.landscape .game-item {
  margin-right: 10px;
}
.game-list-container .game-item {
  position: relative;
  flex-shrink: 0;
  width: 340px;
  height: 268px;
  border-radius: 6px;
  border: 1px solid #b9b9b9;
  overflow: hidden;
  margin-bottom: 14px;
}
.game-list-container .game-item .swiper-container {
  width: 100%;
  height: 192px;
  --swiper-theme-color: #7175a1;
}
.game-list-container .game-item .swiper-container .swiper-slide {
  width: 100%;
  height: 100%;
}
.game-list-container .game-item .swiper-container .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.game-list-container .game-item .swiper-container .swiper-slide .video {
  width: 100%;
  height: 100%;
  position: relative;
}
.game-list-container .game-item .swiper-container .swiper-slide .video .wiki-icon {
  position: absolute;
  display: inline-block;
  width: 41px;
  height: 41px;
  top: 76px;
  left: 150px;
  cursor: pointer;
}
.game-list-container .game-item .swiper-container .swiper-slide .video img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.game-list-container .game-item .swiper-container .custom-swiper-pagination {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  text-align: center;
}
.game-list-container .game-item .swiper-container .custom-swiper-pagination /deep/ .swiper-pagination-bullet {
  margin-right: 4px;
}
.game-list-container .game-item .game-box {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 76px;
  padding: 13px 10px;
  display: flex;
  background-color: #f8f8fa;
}
.game-list-container .game-item .game-box .game-icon {
  flex-shrink: 0;
  width: 51px;
  height: 51px;
  margin-right: 10px;
  border-radius: 4px;
  overflow: hidden;
}
.game-list-container .game-item .game-box .game-icon img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  transition: all 0.2s;
}
.game-list-container .game-item .game-box .game-icon img:hover {
  transform: scale(1.05);
}
.game-list-container .game-item .game-box .game-info {
  flex: 1;
  width: 100%;
}
.game-list-container .game-item .game-box .game-info .game-name {
  height: 24px;
  display: flex;
  margin-bottom: 12px;
  justify-content: space-between;
  align-items: center;
}
.game-list-container .game-item .game-box .game-info .game-name .name {
  flex: 1;
  font-size: 17px;
  font-weight: bold;
  color: #171717;
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.game-list-container .game-item .game-box .game-info .game-name .time {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  height: 21px;
  padding: 2px 8px;
  border-radius: 3px;
  border: 1px solid #bdbdbd;
}
.game-list-container .game-item .game-box .game-info .game-desc {
  height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(51, 51, 51, 0.95);
  line-height: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.game-list-timeline {
  --gap-width: 19px;
  display: flex;
  grid-gap: var(--gap-width);
  overflow: scroll;
  padding: 7px 0px 21px 0px;
}
.game-list-timeline .item .top-box {
  text-align: center;
}
.game-list-timeline .item .top-box .date {
  height: 12px;
  font-size: 12px;
  color: #333;
  font-weight: 500;
  margin-bottom: 9px;
}
.game-list-timeline .item .top-box .line {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}
.game-list-timeline .item .top-box .line:before {
  content: "";
  width: calc(100% + var(--gap-width));
  height: 1px;
  background: #dddddd;
  position: absolute;
  left: 0;
  top: 4px;
}
.game-list-timeline .item .top-box .line .round {
  width: 8px;
  height: 8px;
  background: #d8d8d8;
  border-radius: 50%;
  border: 2px solid #fff;
  position: relative;
  z-index: 1;
}
.game-list-timeline .item .img {
  width: 63px;
  height: 63px;
  border-radius: 4px;
  overflow: hidden;
}
.game-list-timeline .item .img img {
  width: 63px;
  height: 63px;
  transition: all 0.2s;
}
.game-list-timeline .item .img img:hover {
  transform: scale(1.05);
}
.game-list-timeline .item .text-box {
  margin-top: 5px;
  width: 63px;
  overflow: hidden;
}
.game-list-timeline .item .text-box .title {
  height: 35px;
  font-size: 13px;
  font-weight: 400;
  color: #171717;
  text-align: center;
  line-height: 17px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}

.wapNewBanner.landscape {
  display: none;
}
.banner-container {
  width: 100%;
  height: 326px;
  overflow: hidden;
  position: relative;
}
.banner-container .banner-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: #fff;
}
.wap-banner {
  height: 143px;
  margin-bottom: 0;
}
.wap-banner.landscape {
  height: 198px;
}
.wap-banner /deep/ .swiper-pagination {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 10;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  justify-content: center;
}
.wap-banner /deep/ .swiper-pagination .swiper-pagination-bullet {
  width: auto;
  height: 3px;
  background: #c4c4c4;
  opacity: 1;
  border-radius: 0;
  margin-right: 2px;
  flex: 1;
}
.wap-banner /deep/ .swiper-pagination .swiper-pagination-bullet-active {
  background: #6bc7ff;
}
.wap-banner.landscape .banner-item img {
  height: 195px;
}
.wap-banner .banner-item img {
  height: 140px;
}
.pc-banner /deep/ .swiper-pagination {
  position: absolute;
  bottom: 16px;
  right: 24px;
  z-index: 10;
}
.pc-banner /deep/ .swiper-pagination .swiper-pagination-bullet {
  width: 14px;
  height: 4px;
  background: #cccccc;
  border-radius: 3px;
  opacity: 1;
  margin: 0 2px;
}
.pc-banner /deep/ .swiper-pagination .swiper-pagination-bullet-active {
  width: 24px;
  background: #ffb534;
}
.pc-banner .banner-item img {
  height: 326px;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}









































































































































































































































.index-page {
  width: 100%;
}

.newsContainer {
  padding: 0 17px;
  background: #fff;
}
.wiki-list {
  display: flex;
  align-items: flex-start;
  position: relative;
  background: #fff;
  .wiki-list-content {
    width: 100%;
    min-height: 13.33333rem;
    border-radius: 0.16rem;
    margin-bottom: 0.53333rem;
    .list-content {
      margin-top: 18px;
    }
  }
  .sheqTit {
    padding: 0 17px;
    display: flex;
    font-size: 14px;
    margin-top: 14px;
    img {
      display: block;
      width: 17px;
      height: 17px;
      margin-right: 5px;
    }
    span {
      font-size: 14px;
      color: #333;
      line-height: 17px;
      font-weight: 600;
    }
  }
  .list-nav {
    display: flex;
    align-items: stretch;
    padding: 0 17px;
    margin-top: 14px;
    border-bottom: 1px solid rgba(234, 234, 234, 0.8);
    .list-nav-a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #999;
      font-size: 13px;
      border-bottom: 2px solid transparent;
      box-sizing: border-box;
      padding-bottom: 10px;
      & + .list-nav-a {
        margin-left: 25px;
      }

      &.active {
        color: #333;
        font-weight: 600;
        position: relative;

        &:after {
          content: "";
          width: 20px;
          height: 2px;
          border-radius: 2px;
          background: #00afe8;
          position: absolute;
          bottom: -2px;
          left: 50%;
          transform: translateX(-10px);
        }
      }
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";
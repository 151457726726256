



























































































































































































.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.game-recommend {
  &.landscape {
    flex-direction: row;
    .game-video {
      width: 670px;
      height: 350px;
      margin-right: 20px;
      .video {
        width: 670px;
        height: 350px;
      }
    }
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  .game-video {
    width: 340px;
    height: 192px;
    position: relative;
    overflow: hidden;
    margin-bottom: 22px;
    .video {
      width: 340px;
      height: 192px;
      video {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: cover;
      }
    }
    .play-icon {
      display: inline-block;
      width: 41px;
      height: 41px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }

    .title {
      width: 100%;
      max-height: 38px;
      font-size: 14px;
      font-weight: 500;
      color: #171717;
      line-height: 19px;
      margin: 10px 0 23px 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .recommend-list {
    width: 340px;
    height: auto;
    box-sizing: content-box;
    padding: 0;
    display: grid;
    grid-gap: 13px;
    grid-template-columns: repeat(2, 1fr);
    background-size: cover;
    .recommend-item {
      position: relative;
      width: 100%;
      height: 160px;
      border-radius: 6px;
      border: 1px solid #ececec;
      overflow: hidden;
      &.active {
        background-color: #e5f7fc;
        .title {
          color: #00afe8;
        }
      }
      .cover {
        width: 100%;
        height: 108px;
        cursor: pointer;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          transition: all 0.2s;
          &:hover {
            transform: scale(1.05);
          }
        }
      }
      .title {
        height: 38px;
        font-size: 14px;
        font-weight: 500;
        color: #171717;
        line-height: 19px;
        margin: 8px 0;
        padding: 0 8px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
      .info {
        position: absolute;
        top: 87px;
        width: 100%;
        height: 18px;
        font-size: 12px;
        font-weight: 400;
        color: #fff;
        line-height: 18px;
        display: flex;
        justify-content: space-between;
        padding: 0 8px;
        span {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          &:first-child {
            flex: 1;
          }
          .wiki-icon {
            display: inline-block;
            vertical-align: top;
            width: 17px;
            height: 17px;
            margin-right: 2px;
          }
        }
      }
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";



































































.item-group {
  display: flex;
  flex-wrap: wrap;

  &.wap-item-group {
    width: 108 * 4 + 9px * 4;
    box-sizing: content-box;
    height: 398px;

    .item {
      width: 108px;
      height: 182px;
      margin-right: 9px;

      img {
        height: 140px;
        border-radius: 0.0375rem;
      }
    }
  }
  &.landscape {
    width: 100%;
    flex-wrap: nowrap !important;
    height: auto !important;
    overflow-x: auto !important;
    .item {
      flex: 0 0 auto;
      margin-right: 10px;
      &:nth-child(4n) {
        margin-right: 10px;
      }
    }
  }

  .item {
    width: 210px;
    height: 98px + 48px;
    background: #fff;
    margin-right: calc((100% - (210px * 4)) / 3);
    overflow: hidden;
    margin-bottom: 17px;
    &:nth-child(4n) {
      margin-right: 0;
    }
    img {
      width: 100%;
      height: 98px;
      display: block;
      object-fit: cover;
    }

    .text-box {
      height: 42px;
      padding: 6px 2px;
      .title {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #171717;
        font-size: 13px;
        text-align: center;
      }

      .info {
        color: #999;
        font-size: 12px;
        display: flex;
        justify-content: center;
        margin-top: 4px;
        line-height: 14px;
        img {
          display: block;
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}

@hack: true; @import "/drone/src/2.0/src/assets/css/global.less";
#vueWaterfall > .vue-waterfall-column:nth-child(3) .article-list-item {
  margin-left: 0;
}
.clickMore {
  width: 150px;
  height: 28px;
  background: #00afe8;
  border-radius: 7px;
  margin: 12px auto 0;
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  line-height: 28px;
}
.article-list-item {
  width: 100%;
  margin-bottom: 12px;
  padding-bottom: 10px;
  display: block;
  border-radius: 6px;
  background: rgba(248, 249, 249, 0.66);
}
.article-list-item .flex-box .text-box {
  padding: 7px 10px 0;
}
.article-list-item .top-user-box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.article-list-item .top-user-box img {
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 50%;
}
.article-list-item .top-user-box .r-wrapper {
  padding-left: 10px;
  font-size: 12px;
}
.article-list-item .top-user-box .r-wrapper .username {
  max-width: 150px;
  color: #2f2f2f;
  font-weight: 500;
  line-height: 20px;
}
.article-list-item .top-user-box .r-wrapper .date {
  color: #989898;
  font-weight: initial;
  margin-left: 5px;
}
.article-list-item .flex-box.flex-row .text-box {
  width: 100%;
}
.article-list-item .flex-box.flex-row .game-name-desc {
  padding: 8px 10px 0 10px;
}
.article-list-item .flex-box.flex-row .game-name-desc span {
  display: inline-block;
  border: 1px solid #b7b7b7;
  padding: 2px;
  border-radius: 3px;
}
.article-list-item .flex-box.flex-row .game-name-desc img {
  display: block;
  float: left;
  width: 17px;
  height: 17px;
  border-radius: 0.0375rem 0px 0px 0.0375rem;
}
.article-list-item .flex-box.flex-row .game-name-desc em {
  display: block;
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  float: left;
  font-size: 12px;
  color: #6d6d6d;
  font-weight: 400;
  font-style: normal;
  margin-left: 6px;
  line-height: 18px;
}
.article-list-item .flex-box.flex-row.has-img .text-box {
  padding: 0 10px;
  margin-top: 4px;
}
.article-list-item .flex-box.flex-row.has-img .text-box .title {
  font-size: 14px;
  color: #333333;
  line-height: 19px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.article-list-item .flex-box.flex-row.has-img .text-box .desc {
  font-size: 13px;
  color: #999;
  line-height: 17px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 6px;
}
.article-list-item .flex-box.flex-row .img-box {
  width: 100%;
}
.article-list-item .flex-box.flex-row .img-box img {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.article-list-item .flex-box.flex-column {
  flex-direction: column;
}
.article-list-item .flex-box.flex-column .img-box {
  display: flex;
}
.article-list-item .flex-box.flex-column .img-box .item {
  margin-right: 14px;
}
.article-list-item .flex-box.flex-column .img-box .item:last-child {
  margin-right: 0;
}
.article-list-item .flex-box.flex-column .img-box .item img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}
@media screen and (max-width: 500px) {
  .article-list-item .flex-box.flex-column .img-box .item {
    width: calc((100% - 14px * 2) / 3);
    height: 90px;
  }
}
@media screen and (min-width: 500px) {
  .article-list-item .flex-box.flex-column .img-box .item {
    width: 140px;
    height: 90px;
    max-width: calc((100% - (14px * 2)) / 3);
  }
}
.article-list-item .flex-box .text-box {
  cursor: pointer;
}
.article-list-item .flex-box .text-box .title {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  width: 145px;
  font-size: 14px;
  line-height: 24px;
  color: #212121;
}
.article-list-item .flex-box .text-box .title span {
  display: block;
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  width: 145px;
  font-size: 14px;
  line-height: 24px;
  color: #212121;
}
.article-list-item .flex-box .text-box .title .iconfont {
  font-size: 16px;
  margin-right: 5px;
  color: #e74957;
}
.article-list-item .flex-box .text-box .desc {
  font-size: 12px;
  color: #868686;
  line-height: 18px;
  overflow: hidden;
  max-height: 36px;
}
.article-list-item .bottom-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 11px;
  color: #666;
  font-size: 12px;
  padding: 0 10px;
}
.article-list-item .bottom-box .l-box {
  display: flex;
  align-items: center;
}
.article-list-item .bottom-box .l-box .game-name {
  font-size: 12px;
  color: #666;
  background: #f3f3f3;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 22px;
  margin-right: 14px;
}
.article-list-item .bottom-box .l-box .game-name img {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin-right: 6px;
}
.article-list-item .bottom-box .l-box .user {
  display: flex;
  align-items: center;
}
.article-list-item .bottom-box .l-box .user img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 7px;
}
.article-list-item .bottom-box .iconfont {
  margin-right: 4px;
  font-size: 14px;
  color: #000;
}
.article-list-item .bottom-box div {
  display: flex;
  align-items: center;
}
.article-list-item .bottom-box .data-box {
  display: flex;
  align-items: center;
}
.article-list-item .bottom-box .data-box > div {
  display: flex;
  align-items: center;
}
.article-list-item .bottom-box .data-box > div img {
  width: 14px;
  height: 14px;
}
.article-list-item .bottom-box .data-box > div span {
  margin-top: 2px;
  margin-left: 2px;
}
.article-list-item .bottom-box .data-box > div + div {
  margin-left: 12px;
  display: flex;
  align-items: center;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}

.item-group {
  display: flex;
  flex-wrap: wrap;
}
.item-group.wap-item-group {
  width: 468px;
  box-sizing: content-box;
  height: 398px;
}
.item-group.wap-item-group .item {
  width: 108px;
  height: 182px;
  margin-right: 9px;
}
.item-group.wap-item-group .item img {
  height: 140px;
  border-radius: 0.0375rem;
}
.item-group.landscape {
  width: 100%;
  flex-wrap: nowrap !important;
  height: auto !important;
  overflow-x: auto !important;
}
.item-group.landscape .item {
  flex: 0 0 auto;
  margin-right: 10px;
}
.item-group.landscape .item:nth-child(4n) {
  margin-right: 10px;
}
.item-group .item {
  width: 210px;
  height: 146px;
  background: #fff;
  margin-right: calc((100% - (210px * 4)) / 3);
  overflow: hidden;
  margin-bottom: 17px;
}
.item-group .item:nth-child(4n) {
  margin-right: 0;
}
.item-group .item img {
  width: 100%;
  height: 98px;
  display: block;
  object-fit: cover;
}
.item-group .item .text-box {
  height: 42px;
  padding: 6px 2px;
}
.item-group .item .text-box .title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #171717;
  font-size: 13px;
  text-align: center;
}
.item-group .item .text-box .info {
  color: #999;
  font-size: 12px;
  display: flex;
  justify-content: center;
  margin-top: 4px;
  line-height: 14px;
}
.item-group .item .text-box .info img {
  display: block;
  width: 12px;
  height: 12px;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}

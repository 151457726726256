.index-page {
  width: 100%;
}
.newsContainer {
  padding: 0 17px;
  background: #fff;
}
.wiki-list {
  display: flex;
  align-items: flex-start;
  position: relative;
  background: #fff;
}
.wiki-list .wiki-list-content {
  width: 100%;
  min-height: 13.33333rem;
  border-radius: 0.16rem;
  margin-bottom: 0.53333rem;
}
.wiki-list .wiki-list-content .list-content {
  margin-top: 18px;
}
.wiki-list .sheqTit {
  padding: 0 17px;
  display: flex;
  font-size: 14px;
  margin-top: 14px;
}
.wiki-list .sheqTit img {
  display: block;
  width: 17px;
  height: 17px;
  margin-right: 5px;
}
.wiki-list .sheqTit span {
  font-size: 14px;
  color: #333;
  line-height: 17px;
  font-weight: 600;
}
.wiki-list .list-nav {
  display: flex;
  align-items: stretch;
  padding: 0 17px;
  margin-top: 14px;
  border-bottom: 1px solid rgba(234, 234, 234, 0.8);
}
.wiki-list .list-nav .list-nav-a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  font-size: 13px;
  border-bottom: 2px solid transparent;
  box-sizing: border-box;
  padding-bottom: 10px;
}
.wiki-list .list-nav .list-nav-a + .list-nav-a {
  margin-left: 25px;
}
.wiki-list .list-nav .list-nav-a.active {
  color: #333;
  font-weight: 600;
  position: relative;
}
.wiki-list .list-nav .list-nav-a.active:after {
  content: "";
  width: 20px;
  height: 2px;
  border-radius: 2px;
  background: #00afe8;
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-10px);
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}

.wapNewBanner.landscape[data-v-816ca28c] {
  display: none;
}
.banner-container[data-v-816ca28c] {
  width: 100%;
  height: 8.69333rem;
  overflow: hidden;
  position: relative;
}
.banner-container .banner-item img[data-v-816ca28c] {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  background: #fff;
}
.wap-banner[data-v-816ca28c] {
  height: 3.81333rem;
  margin-bottom: 0;
}
.wap-banner.landscape[data-v-816ca28c] {
  height: 5.28rem;
}
.wap-banner[data-v-816ca28c] .swiper-pagination {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 10;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.wap-banner[data-v-816ca28c] .swiper-pagination .swiper-pagination-bullet {
  width: auto;
  height: 0.08rem;
  background: #c4c4c4;
  opacity: 1;
  border-radius: 0;
  margin-right: 0.05333rem;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.wap-banner[data-v-816ca28c] .swiper-pagination .swiper-pagination-bullet-active {
  background: #6bc7ff;
}
.wap-banner.landscape .banner-item img[data-v-816ca28c] {
  height: 5.2rem;
}
.wap-banner .banner-item img[data-v-816ca28c] {
  height: 3.73333rem;
}
.pc-banner[data-v-816ca28c] .swiper-pagination {
  position: absolute;
  bottom: 0.42667rem;
  right: 0.64rem;
  z-index: 10;
}
.pc-banner[data-v-816ca28c] .swiper-pagination .swiper-pagination-bullet {
  width: 0.37333rem;
  height: 0.10667rem;
  background: #cccccc;
  border-radius: 0.08rem;
  opacity: 1;
  margin: 0 0.05333rem;
}
.pc-banner[data-v-816ca28c] .swiper-pagination .swiper-pagination-bullet-active {
  width: 0.64rem;
  background: #ffb534;
}
.pc-banner .banner-item img[data-v-816ca28c] {
  height: 8.69333rem;
}
.ellipsis[data-v-816ca28c] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-816ca28c] {
  *zoom: 1;
}
.clearfix[data-v-816ca28c]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-816ca28c] {
  float: left;
}
.fr[data-v-816ca28c] {
  float: right;
}

.item-group[data-v-164a5559] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}
.item-group.wap-item-group[data-v-164a5559] {
  width: 12.48rem;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 10.61333rem;
}
.item-group.wap-item-group .item[data-v-164a5559] {
  width: 2.88rem;
  height: 4.85333rem;
  margin-right: 0.24rem;
}
.item-group.wap-item-group .item img[data-v-164a5559] {
  height: 3.73333rem;
  border-radius: 0.0375rem;
}
.item-group.landscape[data-v-164a5559] {
  width: 100%;
  -webkit-flex-wrap: nowrap !important;
      -ms-flex-wrap: nowrap !important;
          flex-wrap: nowrap !important;
  height: auto !important;
  overflow-x: auto !important;
}
.item-group.landscape .item[data-v-164a5559] {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  margin-right: 0.26667rem;
}
.item-group.landscape .item[data-v-164a5559]:nth-child(4n) {
  margin-right: 0.26667rem;
}
.item-group .item[data-v-164a5559] {
  width: 5.6rem;
  height: 3.89333rem;
  background: #fff;
  margin-right: calc((100% - (5.6rem * 4)) / 3);
  overflow: hidden;
  margin-bottom: 0.45333rem;
}
.item-group .item[data-v-164a5559]:nth-child(4n) {
  margin-right: 0;
}
.item-group .item img[data-v-164a5559] {
  width: 100%;
  height: 2.61333rem;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
}
.item-group .item .text-box[data-v-164a5559] {
  height: 1.12rem;
  padding: 0.16rem 0.05333rem;
}
.item-group .item .text-box .title[data-v-164a5559] {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #171717;
  font-size: 0.34667rem;
  text-align: center;
}
.item-group .item .text-box .info[data-v-164a5559] {
  color: #999;
  font-size: 0.32rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 0.10667rem;
  line-height: 0.37333rem;
}
.item-group .item .text-box .info img[data-v-164a5559] {
  display: block;
  width: 0.32rem;
  height: 0.32rem;
}
.ellipsis[data-v-164a5559] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-164a5559] {
  *zoom: 1;
}
.clearfix[data-v-164a5559]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-164a5559] {
  float: left;
}
.fr[data-v-164a5559] {
  float: right;
}

.list-box[data-v-efdf301a] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0.18667rem 0 0.56rem 0;
}
.list-box .left-btn[data-v-efdf301a],
.list-box .right-btn[data-v-efdf301a] {
  width: 0.37333rem;
  height: 2.93333rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 0.37333rem;
  background: #e0e0e0;
  cursor: pointer;
  display: none;
}
.list-box .list-content[data-v-efdf301a] {
  width: 21.86667rem;
  --gap-width: 0.50667rem;
  display: grid;
  grid-gap: var(--gap-width);
  grid-template-columns: repeat(10, 1fr);
}
.list-box .list-content .item[data-v-efdf301a] {
  width: 1.68rem;
  height: 3.73333rem;
  font-size: 0.32rem;
  color: #212121;
  line-height: 1;
  text-align: center;
}
.list-box .list-content .item .top-box[data-v-efdf301a] {
  text-align: center;
}
.list-box .list-content .item .top-box .date[data-v-efdf301a] {
  height: 0.32rem;
  font-size: 0.32rem;
  color: #333;
  font-weight: 500;
  margin-bottom: 0.21333rem;
}
.list-box .list-content .item .top-box .line[data-v-efdf301a] {
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 0.32rem;
}
.list-box .list-content .item .top-box .line[data-v-efdf301a]:before {
  content: "";
  width: calc(100% + var(--gap-width));
  height: 0.02667rem;
  background: #dddddd;
  position: absolute;
  left: 0;
  top: 0.08rem;
}
.list-box .list-content .item .top-box .line .round[data-v-efdf301a] {
  width: 0.21333rem;
  height: 0.21333rem;
  background: #d8d8d8;
  border-radius: 50%;
  border: 0.05333rem solid #fff;
  position: relative;
  z-index: 1;
}
.list-box .list-content .item .img[data-v-efdf301a] {
  width: 1.68rem;
  height: 1.68rem;
  border-radius: 0.16rem;
  overflow: hidden;
  margin-bottom: 0.13333rem;
}
.list-box .list-content .item .img img[data-v-efdf301a] {
  width: 100%;
  height: 100%;
}
.list-box .list-content .item .name[data-v-efdf301a] {
  height: 0.93333rem;
  font-size: 0.34667rem;
  font-weight: 400;
  color: #171717;
  line-height: 0.45333rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsis[data-v-efdf301a] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-efdf301a] {
  *zoom: 1;
}
.clearfix[data-v-efdf301a]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-efdf301a] {
  float: left;
}
.fr[data-v-efdf301a] {
  float: right;
}


[data-v-ecfd743a] .video-player {
  background: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}
[data-v-ecfd743a] .video-player .el-dialog__header .el-dialog__headerbtn {
  top: 0.26667rem;
  right: 0.13333rem;
}
[data-v-ecfd743a] .video-player .el-dialog__header .el-dialog__headerbtn .el-icon-close {
  font-size: 0.64rem;
  color: #fff;
}
.custom-title[data-v-ecfd743a] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 10rem;
  margin-bottom: 0.34667rem;
}
.custom-title .title-item[data-v-ecfd743a] {
  display: block;
  width: 100%;
  height: 1.04rem;
  font-size: 0.34667rem;
  font-weight: 400;
  color: #c1c1c1;
  line-height: 1.04rem;
  cursor: pointer;
  background: #eeeeee;
  text-align: center;
}
.custom-title .title-item.active[data-v-ecfd743a] {
  color: #333333;
  background: #fff;
  border-top: 0.02667rem solid #747474;
}
.game-list-container[data-v-ecfd743a] {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.game-list-container.landscape[data-v-ecfd743a] {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
}
.game-list-container.landscape .game-item[data-v-ecfd743a] {
  margin-right: 0.26667rem;
}
.game-list-container .game-item[data-v-ecfd743a] {
  position: relative;
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  width: 9.06667rem;
  height: 7.14667rem;
  border-radius: 0.16rem;
  border: 0.02667rem solid #b9b9b9;
  overflow: hidden;
  margin-bottom: 0.37333rem;
}
.game-list-container .game-item .swiper-container[data-v-ecfd743a] {
  width: 100%;
  height: 5.12rem;
  --swiper-theme-color: #7175a1;
}
.game-list-container .game-item .swiper-container .swiper-slide[data-v-ecfd743a] {
  width: 100%;
  height: 100%;
}
.game-list-container .game-item .swiper-container .swiper-slide img[data-v-ecfd743a] {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.game-list-container .game-item .swiper-container .swiper-slide .video[data-v-ecfd743a] {
  width: 100%;
  height: 100%;
  position: relative;
}
.game-list-container .game-item .swiper-container .swiper-slide .video .wiki-icon[data-v-ecfd743a] {
  position: absolute;
  display: inline-block;
  width: 1.09333rem;
  height: 1.09333rem;
  top: 2.02667rem;
  left: 4rem;
  cursor: pointer;
}
.game-list-container .game-item .swiper-container .swiper-slide .video img[data-v-ecfd743a] {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.game-list-container .game-item .swiper-container .custom-swiper-pagination[data-v-ecfd743a] {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  text-align: center;
}
.game-list-container .game-item .swiper-container .custom-swiper-pagination[data-v-ecfd743a] .swiper-pagination-bullet {
  margin-right: 0.10667rem;
}
.game-list-container .game-item .game-box[data-v-ecfd743a] {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 2.02667rem;
  padding: 0.34667rem 0.26667rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: #f8f8fa;
}
.game-list-container .game-item .game-box .game-icon[data-v-ecfd743a] {
  -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
          flex-shrink: 0;
  width: 1.36rem;
  height: 1.36rem;
  margin-right: 0.26667rem;
  border-radius: 0.10667rem;
  overflow: hidden;
}
.game-list-container .game-item .game-box .game-icon img[data-v-ecfd743a] {
  width: 100%;
  height: 100%;
  border-radius: 0.10667rem;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.game-list-container .game-item .game-box .game-icon img[data-v-ecfd743a]:hover {
  -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
          transform: scale(1.05);
}
.game-list-container .game-item .game-box .game-info[data-v-ecfd743a] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%;
}
.game-list-container .game-item .game-box .game-info .game-name[data-v-ecfd743a] {
  height: 0.64rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.32rem;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.game-list-container .game-item .game-box .game-info .game-name .name[data-v-ecfd743a] {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-size: 0.45333rem;
  font-weight: bold;
  color: #171717;
  line-height: 0.64rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.game-list-container .game-item .game-box .game-info .game-name .time[data-v-ecfd743a] {
  font-size: 0.32rem;
  font-weight: 400;
  color: #666666;
  height: 0.56rem;
  padding: 0.05333rem 0.21333rem;
  border-radius: 0.08rem;
  border: 0.02667rem solid #bdbdbd;
}
.game-list-container .game-item .game-box .game-info .game-desc[data-v-ecfd743a] {
  height: 0.48rem;
  font-size: 0.32rem;
  font-weight: 400;
  color: rgba(51, 51, 51, 0.95);
  line-height: 0.48rem;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.game-list-timeline[data-v-ecfd743a] {
  --gap-width: 0.50667rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  grid-gap: var(--gap-width);
  overflow: scroll;
  padding: 0.18667rem 0 0.56rem 0;
}
.game-list-timeline .item .top-box[data-v-ecfd743a] {
  text-align: center;
}
.game-list-timeline .item .top-box .date[data-v-ecfd743a] {
  height: 0.32rem;
  font-size: 0.32rem;
  color: #333;
  font-weight: 500;
  margin-bottom: 0.24rem;
}
.game-list-timeline .item .top-box .line[data-v-ecfd743a] {
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 0.32rem;
}
.game-list-timeline .item .top-box .line[data-v-ecfd743a]:before {
  content: "";
  width: calc(100% + var(--gap-width));
  height: 0.02667rem;
  background: #dddddd;
  position: absolute;
  left: 0;
  top: 0.10667rem;
}
.game-list-timeline .item .top-box .line .round[data-v-ecfd743a] {
  width: 0.21333rem;
  height: 0.21333rem;
  background: #d8d8d8;
  border-radius: 50%;
  border: 0.05333rem solid #fff;
  position: relative;
  z-index: 1;
}
.game-list-timeline .item .img[data-v-ecfd743a] {
  width: 1.68rem;
  height: 1.68rem;
  border-radius: 0.10667rem;
  overflow: hidden;
}
.game-list-timeline .item .img img[data-v-ecfd743a] {
  width: 1.68rem;
  height: 1.68rem;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.game-list-timeline .item .img img[data-v-ecfd743a]:hover {
  -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
          transform: scale(1.05);
}
.game-list-timeline .item .text-box[data-v-ecfd743a] {
  margin-top: 0.13333rem;
  width: 1.68rem;
  overflow: hidden;
}
.game-list-timeline .item .text-box .title[data-v-ecfd743a] {
  height: 0.93333rem;
  font-size: 0.34667rem;
  font-weight: 400;
  color: #171717;
  text-align: center;
  line-height: 0.45333rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsis[data-v-ecfd743a] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-ecfd743a] {
  *zoom: 1;
}
.clearfix[data-v-ecfd743a]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-ecfd743a] {
  float: left;
}
.fr[data-v-ecfd743a] {
  float: right;
}

.fade-enter-active[data-v-0572cda0],
.fade-leave-active[data-v-0572cda0] {
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}
.fade-enter-from[data-v-0572cda0],
.fade-leave-to[data-v-0572cda0] {
  opacity: 0;
}
.game-recommend[data-v-0572cda0] {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  border-radius: 0.10667rem;
  overflow: hidden;
}
.game-recommend.landscape[data-v-0572cda0] {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
}
.game-recommend.landscape .game-video[data-v-0572cda0] {
  width: 17.86667rem;
  height: 9.33333rem;
  margin-right: 0.53333rem;
}
.game-recommend.landscape .game-video .video[data-v-0572cda0] {
  width: 17.86667rem;
  height: 9.33333rem;
}
.game-recommend .game-video[data-v-0572cda0] {
  width: 9.06667rem;
  height: 5.12rem;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.58667rem;
}
.game-recommend .game-video .video[data-v-0572cda0] {
  width: 9.06667rem;
  height: 5.12rem;
}
.game-recommend .game-video .video video[data-v-0572cda0] {
  width: 100%;
  height: 100%;
  border-radius: 0.10667rem;
  -o-object-fit: cover;
     object-fit: cover;
}
.game-recommend .game-video .play-icon[data-v-0572cda0] {
  display: inline-block;
  width: 1.09333rem;
  height: 1.09333rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
}
.game-recommend .game-video .title[data-v-0572cda0] {
  width: 100%;
  max-height: 1.01333rem;
  font-size: 0.37333rem;
  font-weight: 500;
  color: #171717;
  line-height: 0.50667rem;
  margin: 0.26667rem 0 0.61333rem 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.game-recommend .recommend-list[data-v-0572cda0] {
  width: 9.06667rem;
  height: auto;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  padding: 0;
  display: grid;
  grid-gap: 0.34667rem;
  grid-template-columns: repeat(2, 1fr);
  background-size: cover;
}
.game-recommend .recommend-list .recommend-item[data-v-0572cda0] {
  position: relative;
  width: 100%;
  height: 4.26667rem;
  border-radius: 0.16rem;
  border: 0.02667rem solid #ececec;
  overflow: hidden;
}
.game-recommend .recommend-list .recommend-item.active[data-v-0572cda0] {
  background-color: #e5f7fc;
}
.game-recommend .recommend-list .recommend-item.active .title[data-v-0572cda0] {
  color: #00afe8;
}
.game-recommend .recommend-list .recommend-item .cover[data-v-0572cda0] {
  width: 100%;
  height: 2.88rem;
  cursor: pointer;
  overflow: hidden;
}
.game-recommend .recommend-list .recommend-item .cover img[data-v-0572cda0] {
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.game-recommend .recommend-list .recommend-item .cover img[data-v-0572cda0]:hover {
  -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
          transform: scale(1.05);
}
.game-recommend .recommend-list .recommend-item .title[data-v-0572cda0] {
  height: 1.01333rem;
  font-size: 0.37333rem;
  font-weight: 500;
  color: #171717;
  line-height: 0.50667rem;
  margin: 0.21333rem 0;
  padding: 0 0.21333rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.game-recommend .recommend-list .recommend-item .info[data-v-0572cda0] {
  position: absolute;
  top: 2.32rem;
  width: 100%;
  height: 0.48rem;
  font-size: 0.32rem;
  font-weight: 400;
  color: #fff;
  line-height: 0.48rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 0.21333rem;
}
.game-recommend .recommend-list .recommend-item .info span[data-v-0572cda0] {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.game-recommend .recommend-list .recommend-item .info span[data-v-0572cda0]:first-child {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.game-recommend .recommend-list .recommend-item .info span .wiki-icon[data-v-0572cda0] {
  display: inline-block;
  vertical-align: top;
  width: 0.45333rem;
  height: 0.45333rem;
  margin-right: 0.05333rem;
}
.ellipsis[data-v-0572cda0] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-0572cda0] {
  *zoom: 1;
}
.clearfix[data-v-0572cda0]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-0572cda0] {
  float: left;
}
.fr[data-v-0572cda0] {
  float: right;
}

#vueWaterfall > .vue-waterfall-column:nth-child(3) .article-list-item[data-v-689119fe] {
  margin-left: 0;
}
.clickMore[data-v-689119fe] {
  width: 4rem;
  height: 0.74667rem;
  background: #00afe8;
  border-radius: 0.18667rem;
  margin: 0.32rem auto 0;
  font-size: 0.32rem;
  color: #fff;
  font-weight: 500;
  text-align: center;
  line-height: 0.74667rem;
}
.article-list-item[data-v-689119fe] {
  width: 100%;
  margin-bottom: 0.32rem;
  padding-bottom: 0.26667rem;
  display: block;
  border-radius: 0.16rem;
  background: rgba(248, 249, 249, 0.66);
}
.article-list-item .flex-box .text-box[data-v-689119fe] {
  padding: 0.18667rem 0.26667rem 0;
}
.article-list-item .top-user-box[data-v-689119fe] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 0.26667rem;
}
.article-list-item .top-user-box img[data-v-689119fe] {
  width: 0.96rem;
  height: 0.96rem;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}
.article-list-item .top-user-box .r-wrapper[data-v-689119fe] {
  padding-left: 0.26667rem;
  font-size: 0.32rem;
}
.article-list-item .top-user-box .r-wrapper .username[data-v-689119fe] {
  max-width: 4rem;
  color: #2f2f2f;
  font-weight: 500;
  line-height: 0.53333rem;
}
.article-list-item .top-user-box .r-wrapper .date[data-v-689119fe] {
  color: #989898;
  font-weight: initial;
  margin-left: 0.13333rem;
}
.article-list-item .flex-box.flex-row .text-box[data-v-689119fe] {
  width: 100%;
}
.article-list-item .flex-box.flex-row .game-name-desc[data-v-689119fe] {
  padding: 0.21333rem 0.26667rem 0 0.26667rem;
}
.article-list-item .flex-box.flex-row .game-name-desc span[data-v-689119fe] {
  display: inline-block;
  border: 0.02667rem solid #b7b7b7;
  padding: 0.05333rem;
  border-radius: 0.08rem;
}
.article-list-item .flex-box.flex-row .game-name-desc img[data-v-689119fe] {
  display: block;
  float: left;
  width: 0.45333rem;
  height: 0.45333rem;
  border-radius: 0.0375rem 0 0 0.0375rem;
}
.article-list-item .flex-box.flex-row .game-name-desc em[data-v-689119fe] {
  display: block;
  max-width: 2.93333rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  float: left;
  font-size: 0.32rem;
  color: #6d6d6d;
  font-weight: 400;
  font-style: normal;
  margin-left: 0.16rem;
  line-height: 0.48rem;
}
.article-list-item .flex-box.flex-row.has-img .text-box[data-v-689119fe] {
  padding: 0 0.26667rem;
  margin-top: 0.10667rem;
}
.article-list-item .flex-box.flex-row.has-img .text-box .title[data-v-689119fe] {
  font-size: 0.37333rem;
  color: #333333;
  line-height: 0.50667rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.article-list-item .flex-box.flex-row.has-img .text-box .desc[data-v-689119fe] {
  font-size: 0.34667rem;
  color: #999;
  line-height: 0.45333rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 0.16rem;
}
.article-list-item .flex-box.flex-row .img-box[data-v-689119fe] {
  width: 100%;
}
.article-list-item .flex-box.flex-row .img-box img[data-v-689119fe] {
  width: 100%;
  max-height: 8rem;
  -o-object-fit: cover;
     object-fit: cover;
  border-top-left-radius: 0.16rem;
  border-top-right-radius: 0.16rem;
}
.article-list-item .flex-box.flex-column[data-v-689119fe] {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
.article-list-item .flex-box.flex-column .img-box[data-v-689119fe] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.article-list-item .flex-box.flex-column .img-box .item[data-v-689119fe] {
  margin-right: 0.37333rem;
}
.article-list-item .flex-box.flex-column .img-box .item[data-v-689119fe]:last-child {
  margin-right: 0;
}
.article-list-item .flex-box.flex-column .img-box .item img[data-v-689119fe] {
  width: 100%;
  height: 100%;
  border-radius: 0.10667rem;
  -o-object-fit: cover;
     object-fit: cover;
}
@media screen and (max-width: 500px) {
.article-list-item .flex-box.flex-column .img-box .item[data-v-689119fe] {
    width: calc((100% - 0.37333rem * 2) / 3);
    height: 2.4rem;
}
}
@media screen and (min-width: 500px) {
.article-list-item .flex-box.flex-column .img-box .item[data-v-689119fe] {
    width: 3.73333rem;
    height: 2.4rem;
    max-width: calc((100% - (0.37333rem * 2)) / 3);
}
}
.article-list-item .flex-box .text-box[data-v-689119fe] {
  cursor: pointer;
}
.article-list-item .flex-box .text-box .title[data-v-689119fe] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 3.86667rem;
  font-size: 0.37333rem;
  line-height: 0.64rem;
  color: #212121;
}
.article-list-item .flex-box .text-box .title span[data-v-689119fe] {
  display: block;
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 3.86667rem;
  font-size: 0.37333rem;
  line-height: 0.64rem;
  color: #212121;
}
.article-list-item .flex-box .text-box .title .iconfont[data-v-689119fe] {
  font-size: 0.42667rem;
  margin-right: 0.13333rem;
  color: #e74957;
}
.article-list-item .flex-box .text-box .desc[data-v-689119fe] {
  font-size: 0.32rem;
  color: #868686;
  line-height: 0.48rem;
  overflow: hidden;
  max-height: 0.96rem;
}
.article-list-item .bottom-box[data-v-689119fe] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 0.29333rem;
  color: #666;
  font-size: 0.32rem;
  padding: 0 0.26667rem;
}
.article-list-item .bottom-box .l-box[data-v-689119fe] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.article-list-item .bottom-box .l-box .game-name[data-v-689119fe] {
  font-size: 0.32rem;
  color: #666;
  background: #f3f3f3;
  border-radius: 0.53333rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 0.26667rem;
  height: 0.58667rem;
  margin-right: 0.37333rem;
}
.article-list-item .bottom-box .l-box .game-name img[data-v-689119fe] {
  width: 0.42667rem;
  height: 0.42667rem;
  border-radius: 0.05333rem;
  margin-right: 0.16rem;
}
.article-list-item .bottom-box .l-box .user[data-v-689119fe] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.article-list-item .bottom-box .l-box .user img[data-v-689119fe] {
  width: 0.48rem;
  height: 0.48rem;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  margin-right: 0.18667rem;
}
.article-list-item .bottom-box .iconfont[data-v-689119fe] {
  margin-right: 0.10667rem;
  font-size: 0.37333rem;
  color: #000;
}
.article-list-item .bottom-box div[data-v-689119fe] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.article-list-item .bottom-box .data-box[data-v-689119fe] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.article-list-item .bottom-box .data-box > div[data-v-689119fe] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.article-list-item .bottom-box .data-box > div img[data-v-689119fe] {
  width: 0.37333rem;
  height: 0.37333rem;
}
.article-list-item .bottom-box .data-box > div span[data-v-689119fe] {
  margin-top: 0.05333rem;
  margin-left: 0.05333rem;
}
.article-list-item .bottom-box .data-box > div + div[data-v-689119fe] {
  margin-left: 0.32rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}
.ellipsis[data-v-689119fe] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-689119fe] {
  *zoom: 1;
}
.clearfix[data-v-689119fe]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-689119fe] {
  float: left;
}
.fr[data-v-689119fe] {
  float: right;
}

.index-page[data-v-54e38e44] {
  width: 100%;
}
.newsContainer[data-v-54e38e44] {
  padding: 0 0.45333rem;
  background: #fff;
}
.wiki-list[data-v-54e38e44] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  position: relative;
  background: #fff;
}
.wiki-list .wiki-list-content[data-v-54e38e44] {
  width: 100%;
  min-height: 13.33333rem;
  border-radius: 0.16rem;
  margin-bottom: 0.53333rem;
}
.wiki-list .wiki-list-content .list-content[data-v-54e38e44] {
  margin-top: 0.48rem;
}
.wiki-list .sheqTit[data-v-54e38e44] {
  padding: 0 0.45333rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.37333rem;
  margin-top: 0.37333rem;
}
.wiki-list .sheqTit img[data-v-54e38e44] {
  display: block;
  width: 0.45333rem;
  height: 0.45333rem;
  margin-right: 0.13333rem;
}
.wiki-list .sheqTit span[data-v-54e38e44] {
  font-size: 0.37333rem;
  color: #333;
  line-height: 0.45333rem;
  font-weight: 600;
}
.wiki-list .list-nav[data-v-54e38e44] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  padding: 0 0.45333rem;
  margin-top: 0.37333rem;
  border-bottom: 0.02667rem solid rgba(234, 234, 234, 0.8);
}
.wiki-list .list-nav .list-nav-a[data-v-54e38e44] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #999;
  font-size: 0.34667rem;
  border-bottom: 0.05333rem solid transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-bottom: 0.26667rem;
}
.wiki-list .list-nav .list-nav-a + .list-nav-a[data-v-54e38e44] {
  margin-left: 0.66667rem;
}
.wiki-list .list-nav .list-nav-a.active[data-v-54e38e44] {
  color: #333;
  font-weight: 600;
  position: relative;
}
.wiki-list .list-nav .list-nav-a.active[data-v-54e38e44]:after {
  content: "";
  width: 0.53333rem;
  height: 0.05333rem;
  border-radius: 0.05333rem;
  background: #00afe8;
  position: absolute;
  bottom: -0.05333rem;
  left: 50%;
  -webkit-transform: translateX(-0.26667rem);
      -ms-transform: translateX(-0.26667rem);
          transform: translateX(-0.26667rem);
}
.ellipsis[data-v-54e38e44] {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix[data-v-54e38e44] {
  *zoom: 1;
}
.clearfix[data-v-54e38e44]:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl[data-v-54e38e44] {
  float: left;
}
.fr[data-v-54e38e44] {
  float: right;
}


/*# sourceMappingURL=chunk-16c2bb37.c0b86cdf.css.map*/
.list-box {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 7px 0px 21px 0px;
}
.list-box .left-btn,
.list-box .right-btn {
  width: 14px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background: #e0e0e0;
  cursor: pointer;
  display: none;
}
.list-box .list-content {
  width: 820px;
  --gap-width: 19px;
  display: grid;
  grid-gap: var(--gap-width);
  grid-template-columns: repeat(10, 1fr);
}
.list-box .list-content .item {
  width: 63px;
  height: 140px;
  font-size: 12px;
  color: #212121;
  line-height: 1;
  text-align: center;
}
.list-box .list-content .item .top-box {
  text-align: center;
}
.list-box .list-content .item .top-box .date {
  height: 12px;
  font-size: 12px;
  color: #333;
  font-weight: 500;
  margin-bottom: 8px;
}
.list-box .list-content .item .top-box .line {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}
.list-box .list-content .item .top-box .line:before {
  content: "";
  width: calc(100% + var(--gap-width));
  height: 1px;
  background: #dddddd;
  position: absolute;
  left: 0;
  top: 3px;
}
.list-box .list-content .item .top-box .line .round {
  width: 8px;
  height: 8px;
  background: #d8d8d8;
  border-radius: 50%;
  border: 2px solid #fff;
  position: relative;
  z-index: 1;
}
.list-box .list-content .item .img {
  width: 63px;
  height: 63px;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 5px;
}
.list-box .list-content .item .img img {
  width: 100%;
  height: 100%;
}
.list-box .list-content .item .name {
  height: 35px;
  font-size: 13px;
  font-weight: 400;
  color: #171717;
  line-height: 17px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
